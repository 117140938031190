@media print {
  @page {
    size: portrait;
    -webkit-print-color-adjust: exact;
  }

  .print-table {
    margin: 5px;
    color: black;
    font-family: monospace !important;
  }

  .no-padding {
    margin: 0px !important;
    padding: 0px !important;
    color: black;
    width: 100% !important;
  }
}
